<template>
  <v-expansion-panels class="my-2" v-if="log && log.length > 0" flat v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h3>Лог</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <AuditLogItem v-for="l in log" :key="l.id" :item="l" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import AuditLogItem from "./AuditLogItem.vue";

export default {
  components: {
    AuditLogItem
  },
  props: {
    log: Array
  },
  data() {
    return {
      panel: null
    };
  }
};
</script>

<style lang="sass" scoped>
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap, .v-expansion-panel-header
  padding: 0 !important
</style>
