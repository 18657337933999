<template>
  <div :class="colorClass">
    <span>[{{ item.createdAt | formatDateTime }}]</span>
    <span> {{ item.user.firstName }} {{ item.user.lastName }}</span>
    <b> {{ titles[item.event] }}</b>
    <span> записа </span>
    <div v-if="item.event === 'updated'">
      <small> Стари стойности {{ JSON.stringify(item.oldValues) }}.</small
      ><br />
      <small> Нови стойности {{ JSON.stringify(item.newValues) }}</small>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  data() {
    return {
      titles: {
        created: "създаде",
        deleted: "изтри",
        updated: "обнови"
      },
      valuesKey: {
        created: "newValues",
        updated: "oldValues"
      }
    };
  },
  computed: {
    colorClass() {
      return this.item.event === "created"
        ? "success--text"
        : this.item.event === "updated"
        ? "warning--text"
        : "error--text";
    }
  }
};
</script>

<style></style>
