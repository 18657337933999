<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link
            :to="{
              name: 'edit-service-machines',
              params: { id: $route.params.machineId },
              query: $route.query
            }"
          >
            {{ serviceMachineName }}
          </router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['service-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingServiceMachine"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <ServiceMachineRowsForm
          v-if="!loading"
          ref="ServiceMachineRowsForm"
          :form="form"
        ></ServiceMachineRowsForm>
        <AuditLog v-if="log" :log="log" />
        <v-overlay :value="!firstLoader && isLoadingServiceMachine">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['service-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingServiceMachine"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_MACHINE_FOR_SERVICE } from "@/modules/service-machines/store/service-machines.module";
import {
  FETCH_SERVICE_MACHINE,
  UPDATE_SERVICE_MACHINE,
  DELETE_SERVICE_MACHINE
} from "@/modules/service-machines/store/service-machines.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import KTCard from "@/view/content/Card.vue";
import ServiceMachineRowsForm from "../components/ServiceMachineRowsForm.vue";
import AuditLog from "@/core/components/audit/AuditLog.vue";

import { mapGetters } from "vuex";

export default {
  name: "ServiceMachineRowsEdit",
  components: {
    KTCard,
    SaveButtonDropdown,
    ServiceMachineRowsForm,
    AuditLog
  },
  data() {
    return {
      loading: true,
      errors: {},
      form: {
        machineId: null,
        serviceTypeId: null,
        date: null,
        mileage: null,
        description: null,
        serviceExpenses: []
      },
      machine: {},
      log: null
    };
  },
  computed: {
    ...mapGetters(["isLoadingServiceMachine"]),
    serviceMachineName() {
      return this.machine.fullName;
    }
  },
  mounted() {
    this.fetchServiceMachine();
    this.getServiceMachineRow();
    this.form.machineId = this.$route.params.machineId;
  },
  methods: {
    fetchServiceMachine() {
      this.$store
        .dispatch(FETCH_MACHINE_FOR_SERVICE, this.$route.params.machineId)
        .then(data => {
          this.machine = data.data;

          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$i18n.t("MENU.ITEM.SERVICE.MACHINES"),
              route: { name: "list-service-machines" }
            },
            {
              title: this.serviceMachineName,
              route: {
                name: "edit-service-machines",
                params: { id: this.$route.params.machineId },
                query: this.$route.query
              }
            },
            { title: this.$i18n.t("LABELS.EDIT") }
          ]);
        });
    },
    getServiceMachineRow() {
      let id = this.$route.params.id;
      this.id = id;
      this.$store
        .dispatch(FETCH_SERVICE_MACHINE, id)
        .then(data => {
          this.form = data.data;
          this.log = data.data.audits;
          this.loading = false;
        })
        .catch(response => {
          if (response.status === 404) {
            this.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({
              name: "list-service-machines",
              query: this.$route.query,
            });
          }
        });
    },
    onSave(next) {
      let vm = this;
      this.$refs.ServiceMachineRowsForm.$v.form.$touch();
      if (this.$refs.ServiceMachineRowsForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }

      this.$store
        .dispatch(UPDATE_SERVICE_MACHINE, {
          id: this.id,
          payload: this.form
        })
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          if (next == "continue") {
            //
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-service-machine-rows" });
          }
          if (next == "exit") {
            this.$router.push({
              name: "edit-service-machines",
              params: { id: this.form.machineId },
              query: this.$route.query
            });
          }
        })
        .catch(response => {
          this.errors = this.$errors.format(response.data.errors || {});
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    },
    onDelete() {
      this.$dialog.confirm(this.$t("BUTTON.PLEASE_CONFIRM")).then(() => {
        this.$store
          .dispatch(DELETE_SERVICE_MACHINE, this.$route.params.id)
          .then(data => {
            this.$notify({
              group: "notify",
              type: "success",
              title: "<i class='flaticon2-checkmark'></i> Success",
              text: data.message
            });
            this.$router.push({
              name: "edit-service-machines",
              params: { id: this.form.machineId },
              query: this.$route.query
            });
          })
          .catch(response => {
            this.$notify({
              group: "notify",
              type: "error",
              title: this.$t("ALERTS.ERROR"),
              text: response.data.message
            });
          });
      });
    }
  }
};
</script>
